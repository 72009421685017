$product-slider-width: 625px !default;
$product-slider-height: 921px !default;

$product-thumbnail-width: 94px !default;
$product-thumbnail-height: 148px !default;

$product-slider-border-width: 1px !default;
$product-slider-border-type: solid !default;
$product-slider-border-color: #e5ecef !default;
$product-slider-border: $product-slider-border-width $product-slider-border-type $product-slider-border-color !default;

$product-slider-thumbnail-border-width: $product-slider-border-width !default;
$product-slider-thumbnail-border-type: $product-slider-border-type !default;
$product-slider-thumbnail-border-color: $product-slider-border-color !default;
$product-slider-thumbnail-border: $product-slider-thumbnail-border-width $product-slider-thumbnail-border-type $product-slider-thumbnail-border-color !default;
$product-slider-thumbnail-active-radius: 0px !default;
