$btn-focus-box-shadow: none;
$btn-focus-width: 0px;

@import '../assets/fonts';
@import '../assets/icomoon/index.css';
@import '../node_modules/bootstrap/scss/bootstrap.scss';
@import 'variables';
@import '../node_modules/select2/dist/css/select2.css';
@import '../home';
@import '../partials';
@import '../components';
@import '../product';
@import '../basket';
@import '../orders/checkout';
@import '../list/';
@import '../account/index';
@import '../auth';
@import '../flatpages';
@import '../flatpages/catalog';
@import '../flatpages/blog/';
@import '../sitemap';
@import '../flatpages/smart-shop';
@import '../flatpages/collection/';
@import '../stores';
@import '../common/cookie.scss';
@import '~@fancyapps/fancybox/src/css/core.css';
@import '~@fancyapps/fancybox/src/css/thumbs.css';
@import '~flatpickr/dist/flatpickr.min.css';
@import '~jquery-confirm/css/jquery-confirm.css';
@import '../flatpages/anonymous-order/';
@import '../widgets/blog-banner';
@import '../widgets/blog-list';
@import '../widgets/blog-categories';
@import '../widgets/blog-detail';
@import '../widgets/blog-detail-reco';
@import '../widgets/campaigns';
@import '../widgets/signup-campaign';
@import '../widgets/home-look-by-slider';
@import '../widgets/basket-gift-package';

body {
  font-family: 'Plain Light';
}

h1 {
  font-family: 'Plain Regular';
}

h2, h3, h4, h5, h6 {
  font-family: 'Plain Medium';
}

label.error {
  color: #cc5965;
  display: inline-block;
  margin-left: 5px;
}

.error{
  color: #d72b01;
}

ul {
  list-style: none;
}

a {
  text-decoration: none !important;
}

.--pointer {
  cursor: pointer
}

.color {
  &-primary {
    color: $pz-color-primary-dark !important;
  }

  &-secondary {
    color: #666666 !important;
  }

  &-muted {
    color: #818181 !important;
  }

  &-white {
    color: #ffffff !important;
  }
}

.bg {
  &-dark {
    background-color: $pz-color-primary-dark !important;
  }
}

.text {
  &-underline {
    text-decoration: underline !important;
  }
}

.font {
  &-plain {
    font-family: 'Plain Regular';

    &-bold {
      font-family: 'Plain Bold';
    }

    &-medium {
      font-family: 'Plain Medium';
    }

    &-light {
      font-family: 'Plain Light';
    }
  }
}

.fsize {
  &-12 {
    font-size: 12px;
  }

  &-13 {
    font-size: 13px;
  }

  &-14 {
    font-size: 14px;
  }

  &-22 {
    font-size: 22px !important;
  }

  &-24 {
    font-size: 24px;
  }

  &-36 {
    font-size: 36px;
  }

  &-46 {
    font-size: 46px;
  }

  &-64 {
    font-size: 64px;
  }
}

.flex-1 {
  flex: 1;
}

.full-screen {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 101;
  background-color: $body-bg;

  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__title {
    font-weight: 700;
    font-size: 24px;
    line-height: 1;
  }

  &__close {
    position: absolute;
    right: 16px;
  }
}

.wrapper{
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}

.container-large {
  @include make-container();
  @include make-container-max-widths($pz-container-max-widths);
}

.select2-container {
  width: 100% !important;
}

.modal-content {
  border-radius: 0px;
}

.opacity-1 {
  opacity: 1 !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.button-outline {
  color: #000;
  background-color: #fff;
  border: 1px solid #000;
}

.notFound-wrapper {
  .notFound-content {
    p.header {
      font-size: 72px;
      color:#000;
      text-align: center;
    }
    p.text {
      margin-left:50%;
      transform:translateX(-50%);
      font-size: 16px;
      color:#9d9d9d;
      width:360px;
      height:80px;
      text-align: center;
    }
  }
  button {
    background:#000;
    font-size:14px;
    border-radius:0;
    margin-left:50%; 
    transform:translateX(-50%);
    width:260px; 
    height:50px;
    a {
      color:white; 
    }
  }
  ul {
    margin-top: 40px;
    .notFound-menu-item {
      a{
        color: #000000;
        font-size: 14px;
        text-decoration: underline !important;
      }
    }
  }
}

.first-wrapper-500 {
  padding: 60px 0 60px;
  font-family: Open Sans;
  text-align: center;
  border-bottom: 1px solid #DADADA;
  i {
    font-size: 150px;
  }
}

.second-wrapper-500 {
  padding: 60px 0 85px;
  font-family: Open Sans;
  text-align: center;
  .warning-text {
    color: black;
    font-size: 17px;
  }
}

picture {
  display: block;
}

#vl-header-banner {
  height: 67.95px;
}

@media (max-width: 1620px) {
  #vl-header-banner {
    height: 57.25px;
  }
}

@media (max-width: 1440px) {
  #vl-header-banner {
    height: 50.8px;
  }
}

@media (max-width: 1240px) {
  #vl-header-banner {
    height: 43.67px;
  }
}

@media (max-width: 1000px) {
  #vl-header-banner {
    height: 35.1px;
  }
}

@media (max-width: 768px) {
  #vl-header-banner {
    height: 42px;
  }
}

@media (max-width: 415px) {
  #vl-header-banner {
    height: 23px;
  }
}