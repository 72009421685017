$add-to-cart-color: #fff !default;
$add-to-cart-background: #000 !default;

$add-to-cart-border-width: 1px !default;
$add-to-cart-border-style: solid !default;
$add-to-cart-border-color: transparent !default;

$add-to-cart-border-radius: 0px !default;

$add-to-cart-color-hover: $add-to-cart-color !default;
$add-to-cart-background-hover: #5a5a5a !default;
$add-to-cart-border-color-hover: #3d3d3d !default;